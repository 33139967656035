export const state = {
  redirectUrl: undefined
}

export const mutations = {
  populateRedirectURL (state, redirectUrl) {
    state.redirectUrl = redirectUrl
  },
  clearRedirectURL (state) {
    state.redirectUrl = undefined
  }
}

export const actions = {
  // cannot change data in state, must commit mutation but we can use async
  storeRedirectURL ({ commit }) {
    try {
      const splitUrl = window.location.href.split('/')
      const redirectUrl = splitUrl.splice((3 + splitUrl.length) - splitUrl.length).join('/')
      commit('populateRedirectURL', redirectUrl)
    } catch (e) {
      // dispatch('notify', {message:"Redirect URL Failed", status:false})
    }
  },
  wipeRedirectURL ({ commit }) {
    commit('clearRedirectURL')
  }
}

export const getters = {
  redirectUrl: state => state.redirectUrl
}

const zoomGridIntervals = {
  0: 20,
  1: 15,
  2: 10,
  3: 10,
  4: 5,
  5: 2,
  6: 1,
  7: 0.5,
  8: 0.25,
  9: 0.125,
  10: 0.0625,
  11: 0.03125,
  12: 0.03125,
  13: 0.03125,
  14: 0.015625,
  15: 0.015625,
  16: 0.015625,
  17: 0.0156125,
  18: 0.0156125,
  19: 0.003125,
  20: 0.003125,
  21: 0.0015625,
  22: 0.0015625
}

export default zoomGridIntervals

const awsconfig = {
  aws_project_region: process.env.VUE_APP_REGION,
  aws_cognito_region: process.env.VUE_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_USERPOOLID,
  aws_user_pools_web_client_id: process.env.VUE_APP_USERPOOLWEBCLIENTID,
  identity_pool_id: process.env.VUE_APP_IDENTITY_POOL_ID,
  oauth: {},
  aws_cloud_logic_custom: []
}

export default awsconfig

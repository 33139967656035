<template>
  <div id="frontend-main">
    <div id="nav" v-if="loggedIn">
      <router-link  to="/about">About</router-link>
      <router-link to="/admin">Admin</router-link>
      <router-link to="/map">Map</router-link>
      <!-- <router-link to="/leaflet">Leaflet</router-link> -->
      <!-- <router-link to="/vector-tiles">Vector Tiles</router-link> -->
      <!-- <router-link to="/vector-tiles-advanced">Vector Tiles Advanced</router-link> -->
      <!-- <router-link to="/basic-blue-source-geojson">Basic Blue Source GeoJson</router-link>
      <router-link to="/vector-tile-from-geojson">Vector Tile from GeoJSON</router-link>
      <router-link to="/basic-blue-source-vector-tile">Basic Blue Source Vector Tile</router-link>
      <router-link to="/blue-source-vector">Blue Source Vector</router-link>
      <router-link to="/mbtile">MBTile</router-link> -->
      <button class="navbar-right app-button logout-button" v-on:click="handleLogout">
        <div class="logout-text">Sign Out</div>
      </button>
    </div>
    <div id="frontend-component-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { refreshToken } from "./middleware/refresh-token.js";
import init from "./middleware/init.js";

export default {
  components: {},
  mounted() {
    init();
  },
  data() {
    return {
      isLocked: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "isAuthenticated",
    }),
  },
  methods: {
    ...mapActions(["logout", "wipeRedirectURL"]),
    async handleLogout() {
      await this.logout();
      this.wipeRedirectURL();
      this.$router.push({ path: `/login` });
    },
    async handleSetIdleFalse() {
      this.isLocked = false;
      await refreshToken();
    },
  },
};
</script>

<style lang="scss">
  .frontend-main{
    display:flex;
  }
  //elements
  body{
    height: 100%;
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    animation-name: example;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transition: 1s;
}

@keyframes example {

  0%   {
    background-image:linear-gradient(rgb(255, 255, 200), rgb(245, 255, 200));
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transition: 1s;
  }
  25%  {
    background-image:linear-gradient(rgb(245, 255, 200), rgb(235, 255, 200)); 
    transition:1s;    
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
  50%  {
    background-image:linear-gradient(rgb(235, 255, 200), rgb(225, 255, 200));    
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transition: 1s;
  }
  75%  {
    background-image:linear-gradient(rgb(225, 255, 200),rgb(235, 255, 200));    
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transition:1s;
  }
  100% {background-image:linear-gradient(rgb(235, 255, 200),rgb(245, 255, 200));    
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transition: 1s;
  }
}
  input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  //ids

  #frontend-component-container{
    padding-top:2rem;
    background: rgba(0, 0, 0, 0.01);
  }

  #app h1,h2,h3,h4,h5,h6{
    text-align: center;
  }

  #app table{
    text-align: center;
  }

  #nav {
    position:fixed;
    top:0;
    background-color: rgb(240, 240, 240);
    overflow: hidden;
    display: inline-block;
    width:100%;
    border-bottom: 0.1rem solid rgb(135, 142, 209);
    box-shadow: 0.1rem 0.1rem 0.5rem rgb(80, 84, 119);


    a {
      padding: 14px 16px;
      float: left;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      color: #686868;

      &.router-link-exact-active {
        color: #313131;
      }
    }

    a:hover{
      color:#ffffffd5;
      background-color: rgba(20, 20, 20, 0.5);
      border-radius: 0.5rem;
    }

    a:active{
      color:#ffffff;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  //classes
  .app-logo{
    height:1.2rem;
    width:1.2rem;
    vertical-align: top;
  }
  .app-button{
    height:2rem;
    padding:0.5rem;
    color: white;
    background-color: rgb(37, 37, 37);
    margin-left: 1rem;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .logout-button{
    margin:0.5rem;
    background: rgba(0, 0, 0, 0.5);
    border: 0.1rem solid rgba(0, 0, 0, 0.575);
    box-shadow: 0.1rem 0.1rem 1rem rgb(0, 0, 0);
  }

  .logout-text{
    margin-top:-0.5rem;
  }

  .logout-button:hover{
    background: 0.1rem rgb(10, 10, 10, 0.8);
    border: 0.1rem solid rgb(0, 0, 0);
    box-shadow: 0.05rem 0.05rem 0.5rem rgb(0, 0, 0);
  }

  .logout-button:active{
    background: rgba(20,20,20);
    border: 0.1rem solid rgb(0, 0, 0, 0.5);
    box-shadow: 0.05rem 0.05rem 0.5rem rgb(0, 0, 0);
  }

  .primary-button{
    background-color: #61b80f;
    border: 0.1rem solid #61b80f;
    box-shadow: 0.05rem 0.05rem 0.5rem rgb(44, 139, 25);
  }


  .primary-button:hover{
    background-color: #5aaa0f;
    color: #ffffffe5;
    cursor: pointer;
  }

  .primary-button:active{
    background-color: #437c09;

  }

  .secondary-button{
    margin:0.5rem;
    padding:0.5rem;
    background-color: rgb(177, 177, 255);
    border: rgb(12, 12, 124);
    box-shadow:  0.05rem 0.05rem 0.5rem rgb(8, 8, 70);
  }

  .secondary-button:hover{
    background-color: rgb(177, 177, 252);
    border: rgb(22, 22, 128);
    box-shadow: rgb(13, 13, 68);
    cursor: pointer;
  }

  .secondary-button:active{
    background-color: rgb(130, 130, 255);
    border: rgb(42, 42, 124);
    box-shadow: rgb(23, 23, 66);
  }

  .blank-row td{
    height:0.5rem;
  }

  .frontend-btn-small{
    border-radius: 1rem;
    color: white;
  }

  .frontend-btn-clear{
    background-color: rgb(28, 28, 255);
    border: rgb(12, 12, 124);
    box-shadow:  0.05rem 0.05rem 0.5rem rgb(8, 8, 70);
  }

  .frontend-btn-clear:hover{
    background-color: rgb(67, 67, 252);
    border: rgb(22, 22, 128);
    box-shadow: rgb(13, 13, 68);
    cursor: pointer;
  }

  .frontend-btn-clear:active{
    background-color: rgb(130, 130, 255);
    border: rgb(42, 42, 124);
    box-shadow: rgb(23, 23, 66);
  }

  .frontend-btn-all{
    background-color: #61b80f;
    border: 0.1rem solid #61b80f;
    box-shadow: 0.05rem 0.05rem 0.5rem rgb(44, 139, 25);
  }

  .frontend-btn-all:hover{
    background-color: #5aaa0f;
    color: #ffffffe5;
    cursor: pointer;
  }

  .frontend-btn-all:active{
    background-color: #437c09;
  }

  .frontend-dual-buttons{
    width:5rem;
  }

  .app-middle{
    display: inline-block;
    width:100%;
    margin:auto;
  }

  .navbar-right{
    float:right;
    padding: 14px 16px;
  }

  //overwriting html elements
  h1{
    padding-top:0.5rem;
    padding-bottom:1rem;
    margin:1rem;
    background: rgba(0, 0, 0, 0.01);
    border:0.1rem solid rgba(0, 0, 0, 0.03);
    border-radius:0.25rem;
    color:rgb(50, 50, 50)
  }

  table {
    width: 90%;
    margin:1rem auto;
  }


  table, th, td {
    border:1px solid black;
    line-height: 1.5;
  }

  th{
    background-color: rgb(200, 208, 245);
  }

  tr:nth-child(even) {
    background-color: rgb(226, 230, 245);
    }


 /* component styles */
  .main-container{
    list-style-position: inside;
    text-align: center;
  }
 
</style>

import zoomGridIntervals from '@/data/zoomGridIntervals'
import AWS from 'aws-sdk'
import { configureAWSCredentials } from '@/aws-config'

export const state = {
  map: null,
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  zoomGridIntervals,
  currentZoom: 4,
  currentInterval: 10,
  currentGridLineWidth: 0.1,
  mapLatitude: -68.947,
  mapLongitude: 46.55,
  s3: null,
  s3Config: null,
  metadata: null,
  dataStructure: [],
  availableZoomLevels: [],
  progressVisible: false,
  progressPercentage: 0
}

export const mutations = {
  setInitialisedMap (state, map) {
    state.map = map
  },
  setClearMap (state) {
    state.map = null
  },
  setS3 (state, s3) {
    state.s3 = s3
  },
  setS3Config (state, s3Config) {
    state.s3Config = s3Config
  },
  setMetadata (state, metadata) {
    state.metadata = metadata
  },
  setDataStructure (state, dataStructure) {
    state.dataStructure = dataStructure
  },
  setAvailableZoomLevels (state, zoomLevels) {
    state.availableZoomLevels = zoomLevels
  },
  setCurrentZoom (state, zoom) {
    state.currentZoom = zoom
  },
  setProgressVisible (state, visible) {
    state.progressVisible = visible
  },
  setProgressPercentage (state, percentage) {
    state.progressPercentage = percentage
  }
}

export const actions = {
  async initialiseMapbox({ commit, state }) {
    try {
      mapboxgl.accessToken = state.accessToken
      const map = new mapboxgl.Map({
        container: 'mapContainer',
        style: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true', // style URL
        center: [state.mapLatitude, state.mapLongitude], // starting position
        zoom: state.currentZoom
      })
      commit('setInitialisedMap', map)
    } catch (error) {
      console.error('Error initializing Mapbox:', error)
    }
  },
  async configureAndFetchTiles({ commit, dispatch, state }, user) {
    try {
      if (!user || !user.username || !user.password) {
        throw new Error('User is not authenticated')
      }

      await configureAWSCredentials(user.username, user.password)

      const s3Config = {
        region: 'eu-west-1',
        credentials: AWS.config.credentials,
        httpOptions: {
          timeout: 1200000
        }
      }

      const s3 = new AWS.S3(s3Config)
      commit('setS3', s3)
      commit('setS3Config', { ...s3Config, bucket: 'gsi-portal-2.0' })

      await dispatch('fetchMetadata')
      await dispatch('fetchAvailableZoomLevels')
      await dispatch('fetchDataStructure')
    } catch (error) {
      commit('setProgressVisible', false)
      console.error('Error configuring AWS credentials or fetching vector tiles:', error)
    }
  },
  async fetchMetadata ({ commit, state }) {
    try {
      const params = {
        Bucket: 'gsi-portal-2.0',
        Key: 'tiles/metadata.json'
      }
      const data = await state.s3.getObject(params).promise()
      commit('setMetadata', JSON.parse(data.Body.toString('utf-8')))
      console.log('Fetched metadata:', state.metadata)
    } catch (error) {
      console.error('Error fetching metadata:', error)
    }
  },
  async fetchAvailableZoomLevels ({ commit, state }) {
    try {
      const params = {
        Bucket: 'gsi-portal-2.0',
        Prefix: 'tiles/'
      }
      const listData = await state.s3.listObjectsV2(params).promise()
      const zoomLevels = new Set()
      listData.Contents.forEach(item => {
        const match = item.Key.match(/tiles\/(\d+)\//)
        if (match) {
          zoomLevels.add(parseInt(match[1], 10))
        }
      })
      commit('setAvailableZoomLevels', Array.from(zoomLevels).sort((a, b) => a - b))
      console.log('Available zoom levels:', state.availableZoomLevels)
    } catch (error) {
      console.error('Error fetching available zoom levels:', error)
    }
  },
  async fetchDataStructure ({ commit, state }) {
    try {
      const params = {
        Bucket: 'gsi-portal-2.0',
        Prefix: 'tiles/'
      }
      const listData = await state.s3.listObjectsV2(params).promise()
      commit('setDataStructure', listData.Contents.map(item => item.Key))
      console.log('Fetched data structure:', state.dataStructure)
    } catch (error) {
      console.error('Error fetching data structure:', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
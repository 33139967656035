import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Import Vuex store

const routes = [
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    alias: '/about',
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/',
    name: 'admin',
    component: () => import('../views/AdminView.vue'),
    alias: '/admin',
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map-v3',
    name: 'mapv3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/MapV3.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map-v4',
    name: 'mapv4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/MapV4.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map-v5',
    name: 'mapV5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/MapV5.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map-v6',
    name: 'mapV6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/MapV6.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map',
    name: 'mapV7',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/MapV7.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/vector-tiles-advanced',
    name: 'VectorTileViewAdvanced',
    component: () => import(/* webpackChunkName: "vector-tiles" */ '../views/VectorTileViewAdvanced.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue') // No authentication required
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('../components/Generics/404NotFound.vue'),
    meta: { requiresAuth: true } // Require authentication
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next({ name: 'login' }); // Redirect to login page if not authenticated
    } else {
      next(); // Proceed to the route if authenticated
    }
  } else {
    next(); // Proceed to the route if no authentication is required
  }
});

export default router;
import AWS from 'aws-sdk'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

// Function to get ID token
async function getIdToken (username, password) {
  const cognito = new AWS.CognitoIdentityServiceProvider({ region: awsconfig.aws_project_region })
  const params = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: awsconfig.aws_user_pools_web_client_id,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password
    }
  }
  const authResult = await cognito.initiateAuth(params).promise()
  return authResult.AuthenticationResult.IdToken
}

// Function to configure AWS credentials
export async function configureAWSCredentials (username, password) {
  const idToken = await getIdToken(username, password)
  AWS.config.update({
    region: awsconfig.aws_project_region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsconfig.identity_pool_id,
      Logins: {
        [`cognito-idp.${awsconfig.aws_cognito_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`]: idToken
      }
    })
  })

  return new Promise((resolve, reject) => {
    AWS.config.credentials.get((err) => {
      if (err) {
        console.error('Error getting credentials:', err)
        reject(err)
      } else {
        resolve(AWS.config.credentials)
      }
    })
  })
}

const s3 = new AWS.S3()
export default s3
